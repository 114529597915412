import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter } from 'react-router-dom'
// import reportWebVitals from './reportWebVitals'

import App from './App'
import Main from './Main'

import './index.css'

ReactDOM.render(
  <BrowserRouter>
    <Route path="/" exact={true} component={Main} />
    <Route path="/download" component={App} />
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
