export const languages = {
  'ab': 'Abkhazian',
  'aa': 'Afar',
  'af': 'Afrikaans',
  'ak': 'Akan',
  'sq': 'Albanian',
  'am': 'Amharic',
  'ar': 'Arabic',
  'an': 'Aragonese',
  'hy': 'Armenian',
  'as': 'Assamese',
  'av': 'Avaric',
  'ae': 'Avestan',
  'ay': 'Aymara',
  'az': 'Azerbaijani',
  'bm': 'Bambara',
  'ba': 'Bashkir',
  'eu': 'Basque',
  'be': 'Belarusian',
  'bn': 'Bengali',
  'bh': 'Bihari',
  'bi': 'Bislama',
  'bs': 'Bosnian',
  'br': 'Breton',
  'bg': 'Bulgarian',
  'my': 'Burmese',
  'ca': 'Catalan',
  'ceb': 'Cebuano',
  'ch': 'Chamorro',
  'ce': 'Chechen',
  'yue_hant_hk': 'Chinese, Cantonese',
  'cmn': 'Chinese (China)',
  'zh_tw': 'Chinese (Taiwan)',
  'cu': 'Church Slavic',
  'cv': 'Chuvash',
  'kw': 'Cornish',
  'co': 'Corsican',
  'cr': 'Cree',
  'hr': 'Croatian',
  'cs': 'Czech',
  'da': 'Danish',
  'dv': 'Divehi',
  'nl': 'Dutch',
  'en': 'English',
  'en_us': 'English (US)',
  'eo': 'Esperanto',
  'et': 'Estonian',
  'ee': 'Ewe',
  'fo': 'Faroese',
  'fj': 'Fijian',
  'fil_ph': 'Filipino',
  'fi': 'Finnish',
  'fr': 'French',
  'fy': 'Frisian',
  'ff': 'Fulah',
  'gl': 'Galician',
  'lg': 'Ganda',
  'ka': 'Georgian',
  'de': 'German',
  'el': 'Greek',
  'gn': 'Guarani',
  'gu': 'Gujarati',
  'ht': 'Haitian',
  'ha': 'Hausa',
  'haw': 'Hawaiian',
  'he': 'Hebrew',
  'hz': 'Herero',
  'hi': 'Hindi',
  'ho': 'Hiri Motu',
  'hmn': 'Hmong',
  'hu': 'Hungarian',
  'is': 'Icelandic',
  'io': 'Ido',
  'ig': 'Igbo',
  'id': 'Indonesian',
  'ia': 'Interlingua',
  'ie': 'Interlingue',
  'iu': 'Inuktitut',
  'ik': 'Inupiaq',
  'ga': 'Irish',
  'it': 'Italian',
  'ja': 'Japanese',
  'jv': 'Javanese',
  'kl': 'Kalaallisut',
  'kn': 'Kannada',
  'kr': 'Kanuri',
  'ks': 'Kashmiri',
  'kk': 'Kazakh',
  'km': 'Khmer',
  'ki': 'Kikuyu',
  'rw': 'Kinyarwanda',
  'kv': 'Komi',
  'kg': 'Kongo',
  'ko': 'Korean',
  'kj': 'Kuanyama',
  'ku': 'Kurdish',
  'ky': 'Kyrgyz',
  'lo': 'Lao',
  'la': 'Latin',
  'lv': 'Latvian',
  'li': 'Limburgan',
  'ln': 'Lingala',
  'lt': 'Lithuanian',
  'lu': 'Luba-Katanga',
  'lb': 'Luxembourgish',
  'mk': 'Macedonian',
  'mg': 'Malagasy',
  'ms': 'Malay',
  'ml': 'Malayalam',
  'mt': 'Maltese',
  'gv': 'Manx',
  'mi': 'Maori',
  'mr': 'Marathi',
  'mh': 'Marshallese',
  'mn': 'Mongolian',
  'na': 'Nauru',
  'nv': 'Navajo, Navaho',
  'nd': 'Ndebele, North',
  'nr': 'Ndebele, South',
  'ng': 'Ndonga',
  'ne': 'Nepali',
  'no': 'Norwegian',
  'nn': 'Norwegian Nynorsk',
  'ii': 'Nuosu',
  'ny': 'Nyanja',
  'oc': 'Occitan',
  'or0': 'Odia',
  'oj': 'Ojibwa',
  'om': 'Oromo',
  'os': 'Ossetian',
  'pi': 'Pali',
  'ps': 'Pashto',
  'fa': 'Persian',
  'pl': 'Polish',
  'pt': 'Portuguese',
  'pa': 'Punjabi',
  'qu': 'Quechua',
  'ro': 'Romanian',
  'rm': 'Romansh',
  'rn': 'Rundi',
  'ru': 'Russian',
  'se': 'Sami',
  'sm': 'Samoan',
  'sg': 'Sango',
  'sa': 'Sanskrit',
  'sc': 'Sardinian',
  'gd': 'Gaelic',
  'sr': 'Serbian',
  'st': 'Sesotho',
  'sn': 'Shona',
  'sd': 'Sindhi',
  'si': 'Sinhala',
  'sk': 'Slovak',
  'sl': 'Slovenian',
  'so': 'Somali',
  'es': 'Spanish',
  'su': 'Sundanese',
  'sw': 'Swahili',
  'ss': 'Swati',
  'sv': 'Swedish',
  'tl': 'Tagalog',
  'ty': 'Tahitian',
  'tg': 'Tajik',
  'ta': 'Tamil',
  'tt': 'Tatar',
  'te': 'Telugu',
  'th': 'Thai',
  'bo': 'Tibetan',
  'ti': 'Tigrinya',
  'to': 'Tonga',
  'ts': 'Tsonga',
  'tn': 'Tswana',
  'tr': 'Turkish',
  'tk': 'Turkmen',
  'tw': 'Twi',
  'uk': 'Ukrainian',
  'ur': 'Urdu',
  'ug': 'Uyghur',
  'uz': 'Uzbek',
  've': 'Venda',
  'vi': 'Vietnamese',
  'vo': 'Volapük',
  'wa': 'Walloon',
  'cy': 'Welsh',
  'wo': 'Wolof',
  'xh': 'Xhosa',
  'yi': 'Yiddish',
  'yo': 'Yoruba',
  'za': 'Zhuang',
  'zu': 'Zulu'
}

export const flagCodes = {
  'ab': 'none',
  'aa': 'none',
  'af': 'ZA',
  'ak': 'GH',
  'sq': 'AL',
  'am': 'ET',
  'ar': 'none',
  'an': 'none',
  'hy': 'AM',
  'as': 'none',
  'av': 'none',
  'ae': 'none',
  'ay': 'none',
  'az': 'none',
  'bm': 'ML',
  'ba': 'none',
  'eu': 'none',
  'be': 'BY',
  'bn': 'none',
  'bh': 'none',
  'bi': 'VU',
  'bs': 'BA',
  'br': 'none',
  'bg': 'BG',
  'my': 'MM',
  'ca': 'none',
  'ceb': 'none',
  'ch': 'none',
  'ce': 'none',
  'yue_hant_hk': 'HK',
  'cmn': 'CN',
  'zh_tw': 'TW',
  'cu': 'none',
  'cv': 'none',
  'kw': 'none',
  'co': 'none',
  'cr': 'none',
  'hr': 'HR',
  'cs': 'CZ',
  'da': 'DK',
  'dv': 'MV',
  'nl': 'NL',
  'en': 'GB',
  'en_us': 'US',
  'eo': 'none',
  'et': 'EE',
  'ee': 'none',
  'fo': 'FO',
  'fj': 'FJ',
  'fil_ph': 'PH',
  'fi': 'FI',
  'fr': 'FR',
  'fy': 'none',
  'ff': 'none',
  'gl': 'none',
  'lg': 'UG',
  'ka': 'GE',
  'de': 'DE',
  'el': 'GR',
  'gn': 'none',
  'gu': 'none',
  'ht': 'HT',
  'ha': 'none',
  'haw': 'none',
  'he': 'IL',
  'hz': 'none',
  'hi': 'IN',
  'ho': 'PG',
  'hmn': 'none',
  'hu': 'HU',
  'is': 'IS',
  'io': 'none',
  'ig': 'none',
  'id': 'ID',
  'ia': 'none',
  'ie': 'none',
  'iu': 'none',
  'ik': 'none',
  'ga': 'IE',
  'it': 'IT',
  'ja': 'JP',
  'jv': 'none',
  'kl': 'GL',
  'kn': 'none',
  'kr': 'none',
  'ks': 'none',
  'kk': 'KZ',
  'km': 'KH',
  'ki': 'none',
  'rw': 'RW',
  'kv': 'none',
  'kg': 'none',
  'ko': 'KR',
  'kj': 'none',
  'ku': 'none',
  'ky': 'KG',
  'lo': 'LA',
  'la': 'none',
  'lv': 'LV',
  'li': 'none',
  'ln': 'none',
  'lt': 'LT',
  'lu': 'none',
  'lb': 'LU',
  'mk': 'MK',
  'mg': 'MG',
  'ms': 'MY',
  'ml': 'none',
  'mt': 'MT',
  'gv': 'IM',
  'mi': 'NZ',
  'mr': 'none',
  'mh': 'MH',
  'mn': 'MN',
  'na': 'NR',
  'nv': 'none',
  'nd': 'none',
  'nr': 'none',
  'ng': 'none',
  'ne': 'NP',
  'no': 'NO',
  'nn': 'NO',
  'ii': 'none',
  'ny': 'none',
  'oc': 'none',
  'or0': 'none',
  'oj': 'none',
  'om': 'none',
  'os': 'none',
  'pi': 'none',
  'ps': 'none',
  'fa': 'IR',
  'pl': 'PL',
  'pt': 'PT',
  'pa': 'none',
  'qu': 'none',
  'ro': 'RO',
  'rm': 'none',
  'rn': 'BI',
  'ru': 'RU',
  'se': 'none',
  'sm': 'WS',
  'sg': 'CF',
  'sa': 'none',
  'sc': 'none',
  'gd': 'GB-SCT',
  'sr': 'RS',
  'st': 'none',
  'sn': 'none',
  'sd': 'none',
  'si': 'LK',
  'sk': 'SK',
  'sl': 'SI',
  'so': 'SO',
  'es': 'ES',
  'su': 'none',
  'sw': 'none',
  'ss': 'none',
  'sv': 'SE',
  'tl': 'PH',
  'ty': 'none',
  'tg': 'TJ',
  'ta': 'none',
  'tt': 'none',
  'te': 'none',
  'th': 'TH',
  'bo': 'none',
  'ti': 'none',
  'to': 'TO',
  'ts': 'none',
  'tn': 'none',
  'tr': 'TR',
  'tk': 'TM',
  'tw': 'none',
  'uk': 'UA',
  'ur': 'none',
  'ug': 'none',
  'uz': 'UZ',
  've': 'none',
  'vi': 'VN',
  'vo': 'none',
  'wa': 'none',
  'cy': 'GB-WLS',
  'wo': 'none',
  'xh': 'none',
  'yi': 'none',
  'yo': 'none',
  'za': 'none',
  'zu': 'none'
}

export function getCountryName (countryCode) {
  if (languages.hasOwnProperty(mapForChecksub(countryCode))) { // eslint-disable-line no-prototype-builtins
    return languages[mapForChecksub(countryCode)]
  } else {
    return 'none'
  }
}

export function reConvertCode(countryCode) {
  if (flagCodes.hasOwnProperty(mapForChecksub(countryCode))) { // eslint-disable-line no-prototype-builtins
    return flagCodes[mapForChecksub(countryCode)]
  } else {
    return 'none'
  }
}

export function mapForChecksub(countryCode) {
  let codeForChecksub = ''

  switch(countryCode.toLowerCase()) {
  case 'en-us':
    codeForChecksub = 'en_us'
    break
  case 'fil':
    codeForChecksub = 'fil_ph'
    break
  case 'or':
    codeForChecksub = 'or0'
    break
  case 'zh-hans':
    codeForChecksub = 'cmn'
    break
  case 'zh-hant':
    codeForChecksub = 'zh_tw'
    break
  default:
    codeForChecksub = countryCode.toLowerCase().split('-')[0]
  }

  return codeForChecksub
}

export function reCaptions(ob) {
  return {
    'en': ob['en'],
    'fr': ob['fr'],
    'de': ob['de'],
    'es': ob['es'],
    ...ob
  }
}
