import React, { useCallback, useReducer, useEffect, useRef, useState } from 'react'
import Flag from 'react-flagkit'
import { useLocation } from 'react-router-dom'
import Loader from 'react-loader-spinner'

import {
  fetchVideoInfo,
  downloadAutomaticSrt,
  downloadAutomaticTxt,
  downloadManualSrt,
  downloadManualTxt
} from './utils/api'

import {
  isYoutubeUrl,
  sortByCountryName,
} from './utils/helpers'

import {
  getCountryName,
  reConvertCode,
  reCaptions,
  mapForChecksub,
} from './Country'

import LOGO_ICON from './mainLogo.svg'

import './App.css'

const PRIMARY_LANGUAGES = ['en', 'fr', 'de', 'es']

const initialState = {
  autoCaptions: [],
  manualCaptions: [],
  videoThumbnail: '',
  videoTitle: '',
  videoDuration: null,
  downInfoState: false
}

function reducer(state, action) {
  switch (action.type) {
  case 'START_SETTING_VIDEO_INFO': return startSettingVideoInfo(state)
  case 'SET_VIDEO_INFO': return setVideoInfo(state, action)
  default: return state
  }
}

const startSettingVideoInfo = (state) => {
  return {
    ...state,
    downInfoState: false
  }
}

const setVideoInfo = (state, action) => {
  const { content } = action.data
  const subtitles = content['subtitles']
  const automaticCaptions = content['automatic_captions']

  return {
    ...state,
    videoLanguage: extractVideoLanguage(automaticCaptions, subtitles),
    autoCaptions: extractAutoCaptions(automaticCaptions),
    manualCaptions: extractManualCaptions(subtitles),
    videoThumbnail: action.data.content.thumbnail,
    videoTitle: action.data.content.title,
    videoDuration: new Date(action.data.content.duration * 1000).toISOString().substr(11, 8),
    downInfoState: true
  }
}

const extractVideoLanguage = (automaticCaptions, subtitles) => {
  if (!automaticCaptions && !subtitles) { return '' }

  const captions = automaticCaptions || subtitles
  const list = Object.values(captions)[0]
  const firstCaption = list[0]
  if (!(captions && list && firstCaption)) { return '' }

  const urlSearchParams = new URLSearchParams(firstCaption['url'])
  const params = Object.fromEntries(urlSearchParams.entries())

  return params.lang
}

const extractAutoCaptions = (automaticCaptions) => {
  if (!automaticCaptions) { return [] }

  const unsortedResults = Object.keys(reCaptions(automaticCaptions)).map((key) => {
    return {
      flag: key,
      value: automaticCaptions[key],
      countryCode: reConvertCode(key),
      countryName: getCountryName(key)
    }
  })

  const unsortedResultsOnChecksub = unsortedResults.filter(result => result.countryName !== 'none')
  const primaryLanguages = unsortedResultsOnChecksub.filter(result => PRIMARY_LANGUAGES.includes(result.flag))
  const secondaryLanguages = unsortedResultsOnChecksub.filter(result => !PRIMARY_LANGUAGES.includes(result.flag))
  const sortedSecondaryLanguages = secondaryLanguages.sort(sortByCountryName)

  return primaryLanguages.concat(sortedSecondaryLanguages)
}

const extractManualCaptions = (subtitles) => {
  if (!subtitles) { return [] }

  const unsortedResults = Object.keys(subtitles).map((key) => {
    return {
      flag: key,
      value: subtitles[key],
      countryCode: reConvertCode(key),
      countryName: getCountryName(key)
    }
  })

  const unsortedResultsOnChecksub = unsortedResults.filter(result => result.countryName !== 'none')
  const primaryLanguages = unsortedResultsOnChecksub.filter(result => PRIMARY_LANGUAGES.includes(result.flag))
  const secondaryLanguages = unsortedResultsOnChecksub.filter(result => !PRIMARY_LANGUAGES.includes(result.flag))
  const sortedSecondaryLanguages = secondaryLanguages.sort(sortByCountryName)

  return primaryLanguages.concat(sortedSecondaryLanguages)
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const App = () => {
  let query = useQuery()
  const [state, dispatch] = useReducer(reducer, initialState)
  const videoUrl = useRef(query.get('videoUrl'))
  const [currentVideoUrl, setCurrentVideoUrl] = useState(videoUrl.current)
  const {
    autoCaptions,
    manualCaptions,
    videoThumbnail,
    videoTitle,
    videoLanguage,
    videoDuration,
    downInfoState
  } = state

  const updateVideoUrl = useCallback(e => {
    videoUrl.current = e.target.value
    setCurrentVideoUrl(videoUrl.current)
  }, [])

  const handleFormSubmit = e => {
    e.preventDefault()
    download()
  }

  const download = async () => {
    dispatch({
      type: 'START_SETTING_VIDEO_INFO',
    })

    const result = await fetchVideoInfo(videoUrl.current)

    dispatch({
      type: 'SET_VIDEO_INFO',
      data: {
        content: result
      }
    })
  }

  useEffect(() => {
    setTimeout(download, 100)
  }, [])

  const demo = (item, type) => {
    const currentVideoUrl = videoUrl.current
    const urlPart = type === 'auto' ? 'automatic_srt_download' : 'manual_srt_download'
    const srtUrl = encodeURIComponent(`https://checksub-downloader-doybj.ondigitalocean.app/${urlPart}?lang=${item.flag}&url=${currentVideoUrl}`)
    const subtitleLanguageForChecksub = mapForChecksub(item.flag)
    const videoLanguageForChecksub = mapForChecksub(videoLanguage)

    // window.location.href= `localhost:/external_import?language_subtitle=${subtitleLanguage}&language_video=${videoLanguage}&url=${encodeURI(videoUrl.current)}&srt_url=${srtUrl}`
    window.location.href = `https://my.checksub.com/external_import?language_subtitle=${subtitleLanguageForChecksub}&language_video=${videoLanguageForChecksub}&url=${encodeURIComponent(currentVideoUrl)}&srt_url=${srtUrl}`
  }

  const isLoading = !downInfoState
  const shouldDisableButton = isLoading || currentVideoUrl.length === 0 || !isYoutubeUrl(currentVideoUrl)
  const shouldShowWarning = currentVideoUrl.length !== 0 && !isYoutubeUrl(currentVideoUrl)
  const shouldAllowEdit = false // Switch on/off the import to checksub

  return (
    <div className="main">
      <div className="header">
        <div className="logo">
          <a href="https://checksub.com">
            <img src={LOGO_ICON} style={{ width: 127, height: 'auto' }} alt="logo" />
          </a>
        </div>

        <div className="credential">
          <div className="item">
            <a className="discover_our_subtitling_solution" href="https://www.checksub.com?utm_medium=internally&utm_source=downloader&utm_content=discover our professional subtitling platform / navbar" target="_blank">
              Discover our professional subtitling solution
            </a>
          </div>
        </div>
      </div>

      <div className="content" style={{ marginTop: 122 }}>
        <form className="input" onSubmit={handleFormSubmit}>
          <input
            placeholder="Enter the URL of the video for which you want to download the subtitles"
            className="urlInput"
            onChange={event => updateVideoUrl(event)}
            defaultValue={videoUrl.current}
          />

          <button className="funcBtn" type="submit" disabled={shouldDisableButton}>Download</button>
        </form>
      </div>

      {shouldShowWarning && (
        <div className="warning">
          Only Youtube URLs are allowed
        </div>
      )}

      <div className="content" style={{ marginTop: 15 }}>
        <div className="endTitle">
          Free subtitle file download service for Youtube videos
        </div>
      </div>

      {downInfoState ? (
        <React.Fragment>
          {videoThumbnail && (
            <React.Fragment>
              <div className="content" style={{ marginTop: 54 }}>
                <div className="introTitle">Your video:</div>
              </div>
              <div className="content">
                <div className="videoOutline">
                  <div className="videoThumbnail">
                    <img src={videoThumbnail} alt="videoThumbnail" style={{ width: 168, height: 94}}/>
                  </div>
                  <div className="videoTitle">
                    Title: {videoTitle}<br/>
                    Length: {videoDuration}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {manualCaptions.length > 0 && (
            <React.Fragment>
              <div className="content" style={{  marginTop: 54 }}>
                <div className="introTitle">Subtitles available:</div>
              </div>
              <div className="content">
                <div className="rOption">
                  Manual
                </div>
              </div>
            </React.Fragment>
          )}

          {state.manualCaptions.map((item, index) => {
            const flagExists = item.countryCode !== 'none'

            return (
              <div key={index}>
                <div className="content" style={{ marginTop: 10 }}>
                  <div className="rFlag">
                    {item.countryName}
                    {flagExists && <Flag country={item.countryCode} size={20}/>}
                  </div>
                  <div className="rItem">
                    <button
                      className="txtBtn"
                      onClick={() => downloadManualSrt(item.flag, videoUrl.current, item.countryName, videoTitle)}
                    >
                      SRT
                    </button>
                  </div>
                  <div className="rItem">
                    <button
                      className="txtBtn"
                      onClick={() => downloadManualTxt(item.flag, videoUrl.current, item.countryName, videoTitle)}
                    >
                      TXT
                    </button>
                  </div>
                  {shouldAllowEdit && (
                    <div className="rItem">
                      <button className="editBtn" onClick={() => demo(item, 'manual')}>EDIT IT</button>
                    </div>
                  )}
                </div>
              </div>
            )
          })}

          {manualCaptions.length > 0 && (
            <div className="content">
              <hr style={{ color: '#2D2F36', height: 0, width: 920}}/>
            </div>
          )}

          {autoCaptions.length > 0 && (
            <React.Fragment>
              <div className="content">
                <div className="rOption">
                  Automatic
                </div>
              </div>
            </React.Fragment>
          )}

          {state.autoCaptions.map((item, index) => {
            const flagExists = item.countryCode !== 'none'

            return (
              <div key={index}>
                <div className="content" style={{ marginTop: 10 }}>
                  <div className="rFlag">
                    {item.countryName}
                    {flagExists && <Flag country={item.countryCode} size={20}/>}
                  </div>

                  <div className="rItem">
                    <button
                      className="txtBtn"
                      onClick={() => downloadAutomaticSrt(item.flag, videoUrl.current, item.countryName, videoTitle)}
                    >
                      SRT
                    </button>
                  </div>

                  <div className="rItem">
                    <button
                      className="txtBtn"
                      onClick={() => downloadAutomaticTxt(item.flag, videoUrl.current, item.countryName, videoTitle)}
                    >
                      TXT
                    </button>
                  </div>
                  {shouldAllowEdit && (
                    <div className="rItem">
                      <button className="editBtn" onClick={() => demo(item, 'auto')}>EDIT IT</button>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
          {state.autoCaptions.length === 0 && state.manualCaptions ? (
            <span className="comment">No subtitles available on Youtube</span>
          ) : (
            <span className="comment">You cannot find the language you need?</span>
          )}
          <button className="editBtn editBtn_auto" onClick={() => window.location.href = 'https://www.checksub.com'}>Create your subtitles with Checksub</button>
        </React.Fragment>
      ) : (
        <div className="content" style={{ marginTop: 15, justifyContent: 'center' }}>
          <Loader type="Circles" color="#00BFFF" height={80} width={80}/>
        </div>
      )}
    </div>
  )
}

export default App
