export function isYoutubeUrl(url) {
  const YOUTUBE_VIDEO_ID_LENGTH = 11

  const isFromYoutube = url.includes('www.youtube.com') || url.includes('youtu.be')
  const youtubeRegex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
  const youtubeVideoId = url.match(youtubeRegex) && url.match(youtubeRegex)[1]
  const isValidVideo = youtubeVideoId && youtubeVideoId.length === YOUTUBE_VIDEO_ID_LENGTH

  return isFromYoutube && isValidVideo
}

export function sortByCountryName(a, b) {
  if (a.countryName < b.countryName) {
    return -1
  } else if (a.countryName > b.countryName) {
    return 1
  } else {
    return 0
  }
}
