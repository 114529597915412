import React, { useState } from 'react'

import LOGO_ICON from './mainLogo.svg'

import {
  isYoutubeUrl
} from './utils/helpers'

import './App.css'

function Main() {
  const [videoUrl, setVideoUrl] = useState('')

  const handleFormSubmit = e => {
    e.preventDefault()
    const encodedVideoUrl = encodeURIComponent(videoUrl)
    window.location.href = `/download?videoUrl=${encodedVideoUrl}`
  }

  const shouldDisableButton = videoUrl.length === 0 || !isYoutubeUrl(videoUrl)
  const shouldShowWarning = videoUrl.length !== 0 && !isYoutubeUrl(videoUrl)

  return (
    <React.Fragment>
      <div className="mainTo">
        <div className="header">
          <div className="logo">
            <a href="https://checksub.com">
              <img src={LOGO_ICON} style={{ width: 127, height: 'auto' }} alt="logo" />
            </a>
          </div>

          <div className="credential">
            <div className="item">
              <a className="discover_our_subtitling_solution" href="https://www.checksub.com?utm_medium=internally &utm_source=downloader&utm_content=discover our professional subtitling platform / navbar" target="_blank">
                Discover our professional subtitling solution
              </a>
            </div>
          </div>
        </div>

        <div className="content" style={{ marginTop: 162 }}>
          <div className="introTitle">
            Download and Edit subtitles <br />
            from Youtube videos
          </div>
        </div>

        <div className="content" style={{ marginTop: 56 }}>
          <form className="input" onSubmit={handleFormSubmit}>
            <input
              placeholder="Enter the URL of the video for which you want to download the subtitles"
              className="urlInput"
              onChange={event => setVideoUrl(event.target.value)}
            />

            <button className='funcBtn' type="submit" disabled={shouldDisableButton}>Download</button>
          </form>
        </div>

        {shouldShowWarning && (
          <div className="warning">
            Only Youtube URLs are allowed
          </div>
        )}

        <div className="content" style={{ marginTop: 15 }}>
          <div className="endTitle">
            Free subtitle file download service for Youtube
          </div>
        </div>

        <div className="footer">
          <div className="footerElement">
            FREE
          </div>

          <div className="footerElement">
            NO ADS
          </div>

          <div className="footerElement">
            PRIVATE
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', background: '#1C1D1F'}}>
        <div className="content" style={{ marginTop: 162, fontSize: 45, color: 'white' }}>
          Subtitle creation should be easy.
        </div>

        <div className="content" style={{ marginTop: 162 }}>
          <div style={{ width: 900, fontSize: 38, color: 'white', textAlign: 'center' }}>
            The Checksub downloader allows you to download for free subtitle files from Youtube in SRT and TXT format.<br/>
            We also allow you to edit these subtitles with our innovative online subtitle editor for free.<br/>
            You can create subtitles for your videos or the ones from your favorite creators. 😸
          </div>
        </div>

        <div className="content" style={{ marginTop: 162, marginBottom: 283, flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto' }}>
            <div className="custom" />

            <div className='custom1'>
              To get your subtitles even faster
              <br />
              you can use our&nbsp;
              <a className="extension_link" href="https://chrome.google.com/webstore/detail/checksub-custom-subtitles/lbijplknackhkkopnihoedjjbipoknik?hl=en" target="_blank">
                chrome extension
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Main
